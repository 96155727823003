import { DiffSyncClientKey, DiffSyncPlugin } from '@/diffsync/plugin'
import { AuthenticationContextKey } from '@/iam/types'
import { DiffSyncClient } from '@ankor-io/common/diffsync'
import { Callable } from '@ankor-io/common/lang/functional.types'
import { Inject } from '@/types/inject'

let diffSyncPlugin: DiffSyncPlugin
let _authToken: string | undefined
let _getToken: () => Promise<string | undefined>
/**
 * Class responsible for managing diff sync clients connections
 */
class StateServiceImpl implements StateService {
  /**
   * A record of the currently available diff sync clients
   */
  private clients: Record<string, DiffSyncClient<Object>>

  constructor() {
    // when the service is created the clients record is initialized as an empty
    this.clients = {}
  }

  /**
   * Returns a function that's responsible for destroying the diff sync client
   *
   * @param uri the uri of the diff sync client to destroy
   * @returns a callable that can be used to destroy the diff sync client
   */
  destroy(uri: string): Callable {
    function destructor() {
      // @ts-ignore this is bound to the service instance
      if (this.clients[uri]) {
        // @ts-ignore this is bound to the service instance
        delete this.clients[uri]
      }
    }
    return destructor.bind(this)
  }

  /**
   * Finds the existing diff sync client for the given uri or creates a new one
   *
   * @param uri the uri of the diff sync client to get
   * @returns the diff sync client for the given uri
   */
  getClient(uri: string): DiffSyncClient<Object> {
    if (!this.clients[uri]) {
      this.clients[uri] = diffSyncPlugin.init({
        uri: uri,
        token: _authToken || '',
        getToken: _getToken,
        documentContent: JSON.stringify({
          uri: uri,
        }),
        destructor: this.destroy(uri),
      })
    }
    return this.clients[uri]
  }

  /**
   * Registers an update runnable on the diff sync client for the given uri
   *
   * @param uri the uri of the diff sync client to register the update runnable on
   * @param update the update runnable to register
   */
  registerObserver(uri: string, update: Callable): void {
    if (this.clients[uri]) {
      this.clients[uri].registerObserver(update)
    }
  }

  /**
   * Unregisters an update runnable from the diff sync client for the given uri
   *
   * @param uri the uri of the diff sync client to unregister the update runnable from
   * @param update the update runnable to unregister
   */
  unRegisterObserver(uri: string, update: Callable): void {
    if (this.clients[uri]) {
      this.clients[uri].unRegisterObserver(update)
    }
  }

  onForbidden(uri: string, callback: Callable): void {
    if (this.clients[uri]) {
      this.clients[uri].onForbidden(callback)
    }
  }
}

/**
 * Service managing the different diff sync clients per entity
 */
export interface StateService {
  /**
   * Get the diff sync client synchronizing on a document
   */
  getClient(uri: string): DiffSyncClient<Object>

  /**
   * Add an update runnable to a diff sync client
   *
   * @param update an update runnable to run on incoming changes from the server
   */
  registerObserver(uri: string, update: Callable): void

  /**
   * Remove an update runnable from a client's list of registered observers
   *
   * @param update the update runnable to remove
   */
  unRegisterObserver(uri: string, update: Callable): void

  /**
   * Sets the callback function to be executed when a forbidden error occurs.
   *
   * @param uri - The URI of the diff sync client.
   * @param {Callable} callback - The callback function to be executed.
   *
   * @return {void}
   */
  onForbidden(uri: string, callback: Callable): void

}

/**
 * The state service provider
 */
export type StateServiceProvider = {
  /**
   * @returns the state service instance using a lazy singleton
   */
  get: () => StateService
}

class StateServiceProviderImpl {
  private static instance?: StateService

  public static get(): StateService {
    if (!StateServiceProviderImpl.instance) {
      StateServiceProviderImpl.instance = new StateServiceImpl()
    }
    return StateServiceProviderImpl.instance!
  }
}

/**
 * Allows to access the state service via lazy singleton provider
 *
 * @param inject the vue inject function
 * @returns the state service provider using a lazy singleton
 */
export const useStateServiceProvider = async (inject: Inject): Promise<StateServiceProvider> => {
  diffSyncPlugin = inject(DiffSyncClientKey)!
  const authenticationContext = inject(AuthenticationContextKey)!
  _authToken = await authenticationContext.getToken()
  _getToken = authenticationContext.getToken

  return {
    get: StateServiceProviderImpl.get,
  }
}
