<script setup lang="ts">
import { Ref, inject, ref } from 'vue'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import TwoColumnWithHeader from '@ankor-io/blocks/layouts/TwoColumnWithHeader.vue'
import {
  SolidCommodore,
  SolidContracts,
  SolidDocumentText,
  SolidRadar,
  SolidRoute,
  SolidShip,
} from '@ankor-io/icons/solid'

const authenticationContext: any = inject('authenticationContext')!
const bannerOpen: Ref<boolean> = ref(false)
const [env, tld, _appName, branchName] = location.hostname.split('.').reverse()
const apps = [
  {
    name: 'Yacht Listings',
    icon: SolidRadar,
    appName: 'radar',
  },
  {
    name: 'Fleet Manager',
    icon: SolidCommodore,
    appName: 'calendars',
  },
  {
    name: 'Contracts',
    icon: SolidContracts,
    appName: 'contracts',
  },
].map((app) => ({
  ...app,
  href: `https://${[branchName, app.appName, tld, env].filter((part) => !!part).join('.')}`,
}))
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false"></Banner>
    <TwoColumnWithHeader
      :navigation="[
        {
          name: 'Presentations',
          href: '/trips/library/presentations',
          icon: SolidDocumentText,
          active: $route.path.startsWith('/trips/library/presentations'),
        },
        {
          name: 'Yachts',
          href: '/trips/library/yachts',
          icon: SolidShip,
          active: $route.path.startsWith('/trips/library/yachts'),
        },
        {
          name: 'Routes',
          href: '/trips/library/routes',
          icon: SolidRoute,
          active: $route.path.startsWith('/trips/library/routes'),
        },
      ]"
      :apps="apps"
      :class-names="
        bannerOpen
          ? {
              sidebar: 'lg:top-[2.75rem]',
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #appGroupTitle>
        <div class="text-xs/6 font-semibold text-primary-200">Apps</div>
      </template>
      <template #header>
        <div class="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

            <!-- Profile dropdown -->
            <AccountMenu
              :name="authenticationContext.getUser()?.given_name"
              @logout="$authenticationContext.logout()"
            />
          </div>
        </div>
      </template>
      <template #default>
        <RouterView name="content" />
      </template>
    </TwoColumnWithHeader>
  </div>
</template>
