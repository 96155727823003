import { createRouter, createWebHistory } from 'vue-router'

import TripsDashboard from '@/app/trips/Dashboard.vue'
import PresentationEditor from '@/app/trips/editor/PresentationEditor.vue'
import PresentationEditorDesign from '@/app/trips/editor/PresentationEditorDesign.vue'
import PresentationEditorRoutes from '@/app/trips/editor/PresentationEditorRoutes.vue'
import PresentationEditorYachts from '@/app/trips/editor/PresentationEditorYachts.vue'
import RouteEditor from '@/app/trips/editor/RouteEditor.vue'
import LibraryPresentations from '@/app/trips/library/Presentations.vue'
import LibraryRoutes from '@/app/trips/library/Routes.vue'
import LibraryYachts from '@/app/trips/library/Yachts.vue'
import Preview from '@/components/ProposalPreview.vue'

const routerV2 = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/trips/library',
      name: 'trips-library',
      components: {
        main: TripsDashboard,
      },
      children: [
        {
          path: 'presentations',
          name: 'trips-library-presentations',
          components: {
            content: LibraryPresentations,
          },
        },
        {
          path: 'routes',
          name: 'trips-library-routes',
          components: {
            content: LibraryRoutes,
          },
        },
        {
          path: 'yachts',
          name: 'trips-library-yachts',
          components: {
            content: LibraryYachts,
          },
        },
      ],
    },
    {
      path: '/trips/editor',
      name: 'trips-editor',
      children: [
        {
          path: 'presentation/new',
          name: 'trips-editor-presentation-new',
          components: {
            main: PresentationEditor,
          },
        },
        {
          path: 'presentation/duplicate/:uri',
          name: 'trips-editor-presentation-duplicate',
          components: {
            main: PresentationEditor,
          },
          props: true,
        },
        {
          path: 'presentation/edit/:uri',
          name: 'trips-editor-presentation-edit',
          components: {
            main: PresentationEditor,
          },
          props: true,
          children: [
            {
              path: '',
              components: {
                content: PresentationEditorYachts,
              },
              props: true,
            },
            {
              path: 'yachts',
              name: 'trips-editor-presentation-edit-yachts',
              components: {
                content: PresentationEditorYachts,
              },
              props: true,
            },
            {
              path: 'routes',
              name: 'trips-editor-presentation-edit-routes',
              components: {
                content: PresentationEditorRoutes,
              },
              props: true,
            },
            {
              path: 'design',
              name: 'trips-editor-presentation-edit-design',
              components: {
                content: PresentationEditorDesign,
              },
              props: true,
            },
          ],
        },
        {
          path: 'routes/new',
          name: 'trips-editor-routes-new',
          components: {
            main: RouteEditor,
          },
        },
        {
          path: 'routes/duplicate/:uri',
          name: 'trips-editor-routes-duplicate',
          components: {
            main: RouteEditor,
          },
          props: true,
        },
        {
          path: 'routes/edit/:uri',
          name: 'trips-editor-routes-edit',
          components: {
            main: RouteEditor,
          },
          props: true,
        },
      ],
    },
    {
      path: '/trips/preview/:uri',
      name: 'trips-preview',
      component: Preview,
      props: true,
    },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return {
      // always scroll to top on any route change.
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default routerV2
