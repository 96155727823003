import { COMPATIBILITY_DATES, CartDocument, EditableProposal, Proposal, ProposalItem, ProposalItemType, Template } from '@ankor-io/common/proposal/Proposal'
import { EditableSection, EditableSectionState, Section, SectionTemplate } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableSlideState, ProposalEditableSlide, Slide, SlideTemplate } from '@ankor-io/common/proposal/Slide'
import { SlideType } from '@ankor-io/common/proposal/SlideType'

import { bindings } from '@/services/proposal/Binding'
import { EditableProposalDocument, ProposalDocument } from '@/services/proposal/Document'
import { EditableProposalImpl, ProposalImpl } from '@/services/proposal/ProposalImpl'

/**
 * Build an editable proposal using editable slides and sections.
 *
 * @param defaultTemplate the default template to generate the proposal from
 * @param uri the proposal uri
 * @param proposalItems the proposal entries
 * @param internalName internal name of the proposal
 * @param flow flow of the proposal ('NEEDS_INIT' | 'INITIALIZED')
 * @param indexable the flag to determine if proposal can be indexed
 * @param tags the tags of the proposal
 * @returns the editableProposal
 */
const createEditableProposal = (
  defaultTemplate: Template,
  uri: string,
  proposalItems: ProposalItem[],
  flow: 'NEEDS_INIT' | 'INITIALIZED',
  indexable: boolean,
  tags: string[],
  internalName: string,
  /**
   * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
   */
  externalName?: string,
): EditableProposal => {
  // build the slides
  const slides: ProposalEditableSlide[] = []
  for (const item of proposalItems) {
    const slide: ProposalEditableSlide = bindings.proposalEditableSlides.get(item.type as keyof Template as SlideType)!(
      {
        id: item.uri,
        uri: item.uri,
        proposalUri: uri,
        state: EditableSlideState.NEEDS_HYDRATION,
      },
    )
    // get the slide template so we can start looping the sections
    const slideTemplate: SlideTemplate = defaultTemplate[item.type as keyof Template] as SlideTemplate
    // for each section template in the slide template, create a section
    const sections: EditableSection<any>[] = slideTemplate.sections.map((sectionTemplate: SectionTemplate) => {
      return bindings.proposalsEditableSections.get(sectionTemplate.type as SectionType)!({
        template: sectionTemplate,
        slideUri: slide.getUri(),
        source: null,
        state: EditableSectionState.NEEDS_INIT,
      })
    })
    // add all the sections to the created slide, This is an add operation since we are creating
    // and that's the first time those sections are added to the current slide.
    for (const section of sections) {
      slide.addSection(section)
    }
    slides.push(slide)
  }

  // build the header section if defined
  const header: EditableSection<any> | null = defaultTemplate.header.section
    ? bindings.proposalsEditableSections.get(defaultTemplate.header.section.type as SectionType)!({
        template: defaultTemplate.header.section,
        slideUri: null,
        source: null,
        state: EditableSectionState.NEEDS_INIT,
      })
    : null
  // build the footer section if defined
  const footer: EditableSection<any> | null = defaultTemplate.footer.section
    ? bindings.proposalsEditableSections.get(defaultTemplate.footer.section.type as SectionType)!({
        template: defaultTemplate.footer.section,
        slideUri: null,
        source: null,
        state: EditableSectionState.NEEDS_INIT,
      })
    : null
  // this is ok for now, since creation is only invoked once
  const yachts = proposalItems.filter((item) => item.type === ProposalItemType.VESSEL)
  const itineraries = proposalItems.filter((item) => item.type === ProposalItemType.ITINERARY)
  // in the factory slides should be added via method rather than constructor
  // so hydration is triggered
  const document = new EditableProposalDocument(header, footer, [])
  document.addSlides(slides)
  // create the proposal object and return it
  return new EditableProposalImpl(
    JSON.parse(JSON.stringify(defaultTemplate)),
    document,
    uri,
    proposalItems,
    yachts,
    itineraries,
    flow,
    indexable,
    tags,
    internalName,
    COMPATIBILITY_DATES.LATEST,
    externalName,
  )
}

/**
 * Build a readonly proposal using editable slides and sections.
 *
 * @param defaultTemplate the default template to generate the proposal from
 * @param uri the proposal uri
 * @param proposalItems the proposal entries
 * @param internalName internal name of the proposal
 * @param flow flow of the proposal ('NEEDS_INIT' | 'INITIALIZED')
 * @param indexable the flag to determine if proposal can be indexed
 * @param tags the tags of the proposal
 * @returns the Proposal
 */
const createLiteProposal = (
  defaultTemplate: Template,
  uri: string,
  proposalItems: ProposalItem[],
  tags: string[],
): Proposal => {
  // build the slides
  const slides: Slide[] = []
  for (const item of proposalItems) {
    const slide: Slide = bindings.proposalSlides.get(item.type as keyof Template as SlideType)!({
      id: item.uri,
      uri: item.uri,
      proposalUri: uri,
      state: EditableSlideState.NEEDS_HYDRATION,
    })
    // get the slide template so we can start looping the sections
    const slideTemplate: SlideTemplate = defaultTemplate[item.type as keyof Template] as SlideTemplate
    // for each section template in the slide template, create a section
    const sections: Section<any>[] = slideTemplate.sections.map((sectionTemplate: SectionTemplate) => {
      return bindings.liteSections.get(sectionTemplate.type as SectionType)!({
        template: { ...sectionTemplate, data: null },
        slideUri: slide.getUri(),
        source: null,
      })
    })
    // add all the sections to the created slide, This is an add operation since we are creating
    // and that's the first time those sections are added to the current slide.
    for (const section of sections) {
      slide.addSection(section)
    }
    slides.push(slide)
  }

  // build the header section if defined
  const header: Section<any> | null = defaultTemplate.header.section
    ? bindings.liteSections.get(defaultTemplate.header.section.type as SectionType)!({
        template: { ...defaultTemplate.header.section, data: null },
        slideUri: null,
        source: null,
      })
    : null
  // build the footer section if defined
  const footer: Section<any> | null = defaultTemplate.footer.section
    ? bindings.liteSections.get(defaultTemplate.footer.section.type as SectionType)!({
        template: { ...defaultTemplate.footer.section, data: null },
        slideUri: null,
        source: null,
      })
    : null
  // this is ok for now, since creation is only invoked onc
  const yachts = proposalItems.filter((item) => item.type === ProposalItemType.VESSEL)
  const itineraries = proposalItems.filter((item) => item.type === ProposalItemType.ITINERARY)
  // create the proposal object and return it
  return new ProposalImpl(
    JSON.parse(JSON.stringify(defaultTemplate)),
    new ProposalDocument(header, footer, slides),
    uri,
    proposalItems,
    yachts,
    itineraries,
    'INITIALIZED',
    false,
    tags,
    'Brochure',
    COMPATIBILITY_DATES.LATEST,
  )
}

/**
 * Build an Json slide using editable slide and sections.
 *
 * @param defaultTemplate the default template to generate the slide from
 * @param proposalItem the proposal entry
 * @returns a JsonSlide
 */
const createSlide = (defaultTemplate: Template, proposalItem: ProposalItem, proposalUri: string) => {
  // build the slide
  const slide: ProposalEditableSlide = bindings.proposalEditableSlides.get(
    proposalItem.type as keyof Template as SlideType,
  )!({
    id: proposalItem.uri,
    uri: proposalItem.uri,
    proposalUri: proposalUri,
    state: EditableSlideState.NEEDS_HYDRATION,
  })
  // get the slide template so we can start looping the sections
  const slideTemplate: SlideTemplate = defaultTemplate[proposalItem.type as keyof Template] as SlideTemplate
  // for each section template in the slide template, create a section
  const sections: EditableSection<any>[] = slideTemplate.sections.map((sectionTemplate: SectionTemplate) => {
    return bindings.proposalsEditableSections.get(sectionTemplate.type as SectionType)!({
      template: sectionTemplate,
      slideUri: slide.getUri(),
      source: null,
      state: EditableSectionState.NEEDS_INIT,
    })
  })
  // add all the sections to the created slide, This is an add operation since we are creating
  // and that's the first time those sections are added to the current slide.
  for (const section of sections) {
    slide.addSection(section)
  }

  // create the slide json object and return it
  return slide
}

export const ProposalFactory = {
  create: createEditableProposal,
  createLiteProposal: createLiteProposal,
  createSlide: createSlide,
}

export declare type ProposalFactory = {
  create: (cartDocument: CartDocument, defaultTemplate: Template, uri: string) => EditableProposal
}
