import { COMPATIBILITY_DATES, Proposal, ProposalItemType } from "@ankor-io/common/proposal/Proposal";
import { Function } from "@ankor-io/common/lang/functional.types";

const adapters: Record<string, Function<{ -readonly [P in keyof Proposal]: Proposal[P] }, Proposal>> = {}

/**
 * The first proposal adapter migration
 * @param proposal the proposal to adapt
 */
adapters[0] = (proposal: { -readonly [P in keyof Proposal]: Proposal[P] }): Proposal => {
  /**
   * - it should add a yachts field and push all the yachts respecting the order
   * - it should add an itineraries field and push all the itineraries respecting the order
   * - it should set the proposalItems as empty
   * - it should set the hasItems flag
   * - finally it should update the compatibility date to the next one
   */
  proposal.yachts = proposal.proposalItems.filter(item => item.type === ProposalItemType.VESSEL)
  proposal.itineraries = proposal.proposalItems.filter(item => item.type === ProposalItemType.ITINERARY)
  proposal.hasItems = proposal.yachts.length > 0 && proposal.itineraries.length > 0
  proposal.compatibilityDate = COMPATIBILITY_DATES.getNextDate(proposal.compatibilityDate)
  return proposal
}
/**
 * Every time a new comp date is added make sure to add an adapter function that maps from the previous comp date to the next one
 */

/**
 * Adapts a proposal to the current compatibility date by converting it to the latest version by orderly invoking all the adapters until the latest compatibility date is reached.
 * 
 * @param proposal the proposal to adapt
 */
export const adapt = (proposal: { -readonly [P in keyof Proposal]: Proposal[P] }): Proposal => {
  /**
   * if the comp date is latest then return
   */
  if (proposal.compatibilityDate === COMPATIBILITY_DATES.LATEST) {
    return proposal
  }
  // get the index of the current compatibility date
  const adapterIndex = COMPATIBILITY_DATES.getCurrentDateIndex(proposal.compatibilityDate)

  // start adapting from the current compatibility date to the latest compatibility date
  // this 
  for (let i = adapterIndex; i < COMPATIBILITY_DATES.ALL.length; i++) {
    proposal = adapters[i](proposal)
  }
  return proposal
}