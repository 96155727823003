import { defineStore } from 'pinia'

type SavedStatusState = {
  isSaved: boolean
}

const state = (): SavedStatusState => ({
  isSaved: false,
})

export const useSaveStatusStore = defineStore('save-status', {
  state,
})
