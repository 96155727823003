import mixpanel, { Mixpanel } from 'mixpanel-browser'
import { App } from 'vue'

import { MixPanelPlugin } from '@/utils/mixPanel/types'


/**
 * MixPanel instance
 */
export const mixPanelInstance = (function () {
  let _mixPanel: Mixpanel

  const initializeMixPanel = async (token: string): Promise<Mixpanel | undefined> => {
    try {
      _mixPanel = mixpanel.init(
        token,
        {
          track_pageview: false,
          persistence: 'localStorage',
        },
        'trips',
      )
      return _mixPanel
    } catch (e) {
      console.error('MixPanel Vue plugin error', e)
      return
    }
  }

  const createMixPanelClient = async (token: string): Promise<MixPanelPlugin> => {
    await initializeMixPanel(token)
    return {
      install: (app: App): void => {
        app.config.globalProperties.$mixPanel = _mixPanel
        app.provide('mixPanel', _mixPanel)
      },
    }
  }

  return {
    createMixPanelClient,
  }
})()
